
.skill-category {
	text-align: left;
	display: inline-block;
	vertical-align: top;
	margin: 2rem 0.5rem;
	width: 160px;
}

.skill-category span {
	font-size: 1.8em;
	border-left: 1px solid transparent;
	padding-left: 0.5rem;
}

.skill-category ul {
	/* border-left: 1px solid pink; */
	border-left: 1px solid #00ffff;
	padding: 0.5rem;
	font-size: 1.2em;
}

