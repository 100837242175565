@import url(https://fonts.googleapis.com/css2?family=Baskervville&family=Handlee&family=Open+Sans&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	/* background-color: #363636; */
	/* background-image: url("https://www.transparenttextures.com/patterns/asfalt-light.png"); */
	/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style: none;
}

a {
	text-decoration: none;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.skill-category {
	text-align: left;
	display: inline-block;
	vertical-align: top;
	margin: 2rem 0.5rem;
	width: 160px;
}

.skill-category span {
	font-size: 1.8em;
	border-left: 1px solid transparent;
	padding-left: 0.5rem;
}

.skill-category ul {
	/* border-left: 1px solid pink; */
	border-left: 1px solid #00ffff;
	padding: 0.5rem;
	font-size: 1.2em;
}


